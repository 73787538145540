const maxNumber = "99999999999999999999";
export const roundDecimal = (value) => {
  if (value > parseFloat(maxNumber)) {
    return value;
  }
  value = parseFloat(value);
  let intergerFlag = Number.isInteger(value);
  if (isNaN(value)) {
    return null;
  }
  return intergerFlag
    ? value.toFixed(2)
    : (Math.floor((value * 100).toFixed(2)) / 100).toFixed(2);
};
export const roundInteger = (value) => {
  if (value > parseInt(maxNumber, 10)) {
    return value;
  }
  value = parseInt(value, 10);
  if (isNaN(value)) {
    return null;
  }
  return value;
};
export const convertValueToString = (value) => {
  if (value === null || value === undefined) {
    return "";
  }
  return value.toString();
};
export const interpolationStation = (
  nearest_railway,
  nearest_station,
  walk_to_near_station
) => {
  let nearest_railway_string = nearest_railway ? `${nearest_railway}` : ``;
  let nearest_station_string = nearest_station
    ? `「${nearest_station}」駅`
    : ``;
  let walk_to_near_station_string = walk_to_near_station
    ? ` 徒歩${walk_to_near_station}分`
    : ``;
  return (
    nearest_railway_string +
    nearest_station_string +
    walk_to_near_station_string
  );
};
export const scrollToTopElement = (
  querySelectorList = [],
  validElementFunc = null
) => {
  // querySelectorList is a list of selector to find the element
  // ValidElementFunc is a function that return true or false to check if the element is valid
  let validElementArray = [];
  // Get the elements that matches the selector
  for (const item of querySelectorList) {
    let errorElements = document.querySelectorAll(item);
    if (validElementFunc) {
      errorElements = Array.from(errorElements).filter((element) =>
        validElementFunc(element)
      );
    }
    // Get the first element
    validElementArray.push(errorElements[0]);
  }
  // Get the highest top position
  let topPosition = 0;
  for (const item of validElementArray) {
    if (item) {
      let position = item.getBoundingClientRect().top;
      if (position < topPosition) {
        topPosition = position;
      }
    }
  }
  // Do scroll
  let moveToElement = validElementArray.find(
    (element) => element.getBoundingClientRect().top === topPosition
  );
  if (moveToElement) {
    moveToElement.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

export function splitIntoPages(array, size) {
  return array.reduce((acc, val, i) => {
    const idx = Math.floor(i / size);
    if (!acc[idx]) acc[idx] = [];
    acc[idx].push(val);
    return acc;
  }, []);
}
