import store from "../store";
import axios from "axios";
const checkNested = (obj, level, ...rest) => {
  if (obj === undefined) return false;
  if (rest.length == 0 && level in obj) return true;
  return checkNested(obj[level], ...rest);
};
const getParamsUrl = () => {
  const params = {};
  const searchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of searchParams) {
    params[key] = value;
  }
  return params;
};
const changePageCallback = (component, size) => {
  const changePage = (page) => {
    let params = getParamsUrl();
    params = { ...params, page };
    let url = new URL(window.location);
    url.search = new URLSearchParams(params).toString();
    component.$router.push(url.pathname + url.search);
  };
  return changePage;
};
const handleErrorResponse = (error) => {
  let [error_msg, loc] = getMessageFromError(error);
  if (loc == "pop-up") {
    store.commit("confirmapi/setConfirm", {
      status: true,
      mes: error_msg,
    });
  }
  return false;
};
const getMessageFromError = (errors) => {
  let detail_array = errors.response.data.detail;
  let msg = detail_array[0].msg;
  let loc = detail_array[0].loc[1];
  return [msg, loc];
};
const downloadFile = (url, params, fileName) => {
  axios.get(url, { params, responseType: "blob" }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  });
};
const uploadFile = (method, url, params, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios({
    method,
    url,
    params,
    data: formData,
  });
};
export {
  checkNested,
  changePageCallback,
  getParamsUrl,
  handleErrorResponse,
  downloadFile,
  uploadFile,
};
