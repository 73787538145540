const dataContactTime = [
  { id: 1, text: "いつでも可" },
  { id: 2, text: "午前中（10:00～12:00）" },
  { id: 3, text: "昼頃（12:00～13:00）" },
  { id: 4, text: "午後（13:00～15:00）" },
  { id: 5, text: "夕方（15:00～17:00）" },
  { id: 6, text: "夜（17:00～）" },
];
const dataAttendees = [
  { id: 1, text: 1 },
  { id: 2, text: 2 },
  { id: 3, text: 3 },
  { id: 4, text: 4 },
  { id: 5, text: 5 },
];
export { dataContactTime, dataAttendees };
