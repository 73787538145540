import axios from "axios";
import {
  RECOMMEND_PROPERTIES_API,
  RECOMMEND_EMAILS,
  PROPERTY_BLOCKS_API,
  NEW_MAIL_PROPERTIES,
} from "./const.service.js";

const getRecommendProperties = async (uuid) => {
  try {
    const response = await axios.get(`${RECOMMEND_PROPERTIES_API}/${uuid}`);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const getRecommendEmail = async (params) => {
  try {
    return axios.get(RECOMMEND_EMAILS + "/report", { params });
  } catch (error) {
    console.log(error);
  }
};
const getRecommendMailList = async (params) => {
  return axios
    .get(RECOMMEND_EMAILS, { params: params })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};
const getRecommendSendgridEmail = async (uid) => {
  try {
    const response = await axios.get(RECOMMEND_EMAILS + "/" + uid);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const inCreasePageView = async (uuid) => {
  try {
    const response = axios.post(
      `${RECOMMEND_PROPERTIES_API}/${uuid}/increase_pageview`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const sendFreeMails = async (body) => {
  return axios
    .post(RECOMMEND_EMAILS + "/send_free_mail", body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};
const getRecommendNewMail = async (uuid, param) => {
  try {
    const response = await axios.get(`${PROPERTY_BLOCKS_API}/${uuid}`, {
      params: param,
    });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getNewMailHTML = async (uuid, description) => {
  return axios.get(RECOMMEND_EMAILS + `/mail_preview/${uuid}`, {
    params: { description },
  });
};

const getNewMailPropertyDetail = async (uuid) => {
  try {
    const response = await axios.get(`${NEW_MAIL_PROPERTIES}/${uuid}`);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const inCreasePageViewNewMail = async (uuid) => {
  try {
    const response = axios.post(
      `${NEW_MAIL_PROPERTIES}/${uuid}/increase_pageview`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export {
  getRecommendProperties,
  getRecommendEmail,
  getRecommendMailList,
  getRecommendSendgridEmail,
  inCreasePageView,
  sendFreeMails,
  getRecommendNewMail,
  getNewMailHTML,
  getNewMailPropertyDetail,
  inCreasePageViewNewMail,
};
