<template>
  <BaseSingleLayout>
    <router-view></router-view>
  </BaseSingleLayout>
</template>

<script>
import BaseSingleLayout from "/src/components/layouts/BaseSingleLayout.vue";

export default {
  name: "InquiryMain",
  components: {
    BaseSingleLayout,
  },
};
</script>
