<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <h3 class="card-page-title mt-4 mb-3">
    <img
      src="@/assets/img/property-feat/feat-form-title.png"
      alt="title"
      class="img-fluid" />
  </h3>
  <div v-if="isLoad" class="wrap-ct border-0">
    <div class="text-blue3 my-4 text-center font-weight-bold fs-20">
      {{ infoPage.title }} お問合せフォーム
    </div>
    <form ref="inquiryform">
      <div class="custom-callout">
        お問合せ内容 <span class="badge badge-red fs-12">必 須</span>
      </div>
      <div class="cus-filter custom-form border-0 pl-3 pl-sm-5 mb-5">
        <div class="form-group">
          <div class="form-check custom-checkbox">
            <input
              id="property"
              v-model="dataParams.inquiryCheck1"
              class="form-check-input"
              :class="{
                'field-error': !isCheckBox() && startCheck && !formConfirm(),
              }"
              type="checkbox"
              :disabled="formConfirm()" />
            <label for="property">物件の詳細が知りたい</label>
          </div>
        </div>
        <div class="form-group">
          <div class="form-check custom-checkbox">
            <input
              id="plans"
              v-model="dataParams.inquiryCheck2"
              class="form-check-input"
              :class="{
                'field-error': !isCheckBox() && startCheck && !formConfirm(),
              }"
              type="checkbox"
              :disabled="formConfirm()" />
            <label for="plans"
              >他にどのような間取り・価格があるのか知りたい</label
            >
          </div>
        </div>
        <div class="form-group">
          <div class="form-check custom-checkbox">
            <input
              id="schedule"
              v-model="dataParams.inquiryCheck3"
              class="form-check-input"
              :class="{
                'field-error': !isCheckBox() && startCheck && !formConfirm(),
              }"
              type="checkbox"
              :disabled="formConfirm()" />
            <label for="schedule">販売スケジュールが知りたい</label>
          </div>
        </div>
        <div class="form-group">
          <label class="font-weight-normal mt-sm-2">その他お問合せ</label>
          <textarea
            v-model="dataParams.request"
            rows="4"
            class="form-control"
            :class="{
              'field-error': !isCheckBox() && startCheck && !formConfirm(),
            }"
            maxlength="9999"
            :disabled="formConfirm()"></textarea>
        </div>
        <div
          v-if="!isCheckBox() && startCheck && !formConfirm()"
          class="form-error text-red2 fs-12 mb-2">
          入力項目に不足、または不備があります。
        </div>
      </div>
      <div class="custom-callout">ご連絡先</div>
      <div class="cus-filter custom-form border-0 pl-3 pl-sm-5">
        <p class="color_a0a0a0">
          ※お電話またはメールにて担当者よりご連絡させていただきます。<br />
          定休日の場合、ご連絡までに3日程度時間を要する場合がございます。<br />
          予めご了承ください。
        </p>
        <div class="row">
          <label class="col-sm-4 font-weight-normal mt-sm-2"
            >電話番号 <span class="badge badge-red">必 須</span></label
          >
          <div class="col-sm-6">
            <div class="form-group">
              <input
                v-model="dataParams.phone"
                type="tel"
                class="form-control"
                :class="{
                  'field-error':
                    dataParams.phone == '' && startCheck && !formConfirm(),
                }"
                data-toggle="datetimepicker"
                maxlength="15"
                :disabled="formConfirm()"
                @keypress="checkPhone"
                @paste="checkPhone" />
            </div>
            <div
              v-if="dataParams.phone == '' && startCheck && !formConfirm()"
              class="form-error text-red2 fs-12 mb-2">
              入力項目に不足、または不備があります。
            </div>
            <div
              v-if="checkIsPhone() && dataParams.phone != ''"
              class="form-error text-red2 fs-12 mb-2">
              電話番号は半角数字、および半角ハイフン(-)のみ使用できます。
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 font-weight-normal mt-sm-2"
            >ご希望連絡時間帯</label
          >
          <div class="col-sm-8">
            <div class="custom-select2 w-100 form-group">
              <FieldSelect2
                v-model="dataParams.selectContactTime"
                :options="dataContactTime"
                :keyid="'text'"
                :keytext="'text'"
                :disabled="formConfirm()" />
            </div>
          </div>
        </div>
        <p class="mt-5">個人情報の取り扱いについて（必ずお読みください）</p>
        <p>
          <a
            href="https://www.livable.co.jp/Privacy_policy.html"
            class="bg-arrow-right txt-underline"
            target="_blank"
            >東急リバブル プライバシーポリシー</a
          >
        </p>
        <div class="form-group">
          <div class="form-check custom-checkbox mr-3">
            <input
              id="inquiry-confirm"
              v-model="isConfirm"
              class="form-check-input"
              :class="{
                'field-error': !isConfirm && startCheck && !formConfirm(),
              }"
              type="checkbox"
              :disabled="formConfirm()" />
            <label for="inquiry-confirm">上記に同意する</label>
          </div>
          <div
            v-if="!isConfirm && startCheck && !formConfirm()"
            class="form-error text-red2 fs-12 mt-2">
            入力項目に不足、または不備があります。
          </div>
        </div>
        <div v-if="formConfirm()" class="my-3">
          上記入力内容に間違いがなければ【送信】ボタンをクリックしてください。
        </div>
      </div>
      <div class="text-center mb-5">
        <template v-if="!formConfirm()">
          <button
            type="button"
            class="btn submit-pink m-3"
            :disabled="!isEnterFeild()"
            @click="checkSubmit">
            確認画面
          </button>
          <button
            v-if="recommendPropertiesUrl && pageParam.outcome !== 'email'"
            type="button"
            class="btn submit-pink submit-border-pink m-3"
            @click="backRecomend">
            物件紹介ページに戻る
          </button>
        </template>
        <template v-else>
          <button
            type="button"
            class="btn submit-pink m-3"
            @click="showModal('modalConfirm')">
            <span>送 信</span>
          </button>
          <button
            type="button"
            class="btn submit-pink submit-border-pink m-3"
            @click="backForm">
            <span>戻 る</span>
          </button>
        </template>
        <p v-if="pageParam.outcome === 'email'" class="color_a0a0a0">
          中止する場合はブラウザを閉じてください。
        </p>
      </div>
    </form>
  </div>
  <ModalConfirmSubmit
    :id="'modalConfirm'"
    :message="'お問合せフォームを送信します。'"
    @yes="submitForm" />
</template>

<script>
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2.vue";
import { dataContactTime, dataAttendees } from "./data.js";
import {
  addInquiryHistories,
  checkInquiryHistories,
} from "/src/service/inquiry.service.js";
import { getNewMailPropertyDetail } from "/src/service/recommend.service.js";
// import { getFollowPriorityCustomers } from "/src/service/priorities-customers.js";
import ModalConfirmSubmit from "/src/components/modals/ModalConfirmSubmit.vue";
import { mixinModal } from "/src/mixins";

export default {
  name: "InquiryForm",
  components: {
    FieldSelect2,
    ModalConfirmSubmit,
  },
  mixins: [mixinModal],
  data() {
    return {
      dataParams: {
        inquiryCheck1: false,
        inquiryCheck2: false,
        inquiryCheck3: false,
        request: "",
        phone: "",
        selectContactTime: null,
      },
      isConfirm: false,
      startCheck: false,
      isSubmit: false,
      dataContactTime,
      dataAttendees,
      recommendProperties: "",
      recommendMailId: 0,
      infoPage: {
        title: "",
      },
      recommendPropertiesUrl: "",
      typeSubmit: null,
      pageParam: {
        recommend_page_url: null,
        outcome: null,
        pre_submit: true,
      },
      mailParam: {
        recommend_mail_id: null,
        customer_id: null,
        pre_submit: true,
      },
      followParam: {
        history_id: null,
        customer_id: null,
        reference_page_url: null,
        pre_submit: true,
      },
      isLoad: false,
    };
  },
  watch: {
    followDate() {
      this.changeFollowDate();
    },
  },
  created() {
    this.checkInquirydata();
  },
  methods: {
    async fetchInquiry(param, type) {
      const response = await checkInquiryHistories(param);
      if (response) {
        this.isLoad = true;
        this.typeSubmit = type;
        if (response.data.property_name) {
          this.infoPage.title = response.data.property_name;
          localStorage.setItem("inquirytitle", response.data.property_name);
        }
      } else {
        this.$router.push({ name: "error404" });
      }
    },
    async fetchNewMail(newuuid) {
      const response = await getNewMailPropertyDetail(newuuid);
      if (response.data) {
        this.isLoad = true;
        this.typeSubmit = "urlnew";
        this.recommendPropertiesUrl = response.data.new_mail_property_page_url;
        this.infoPage.title = response.data.property.name;
        localStorage.setItem("inquirytitle", response.data.property.name);
      } else {
        this.$router.push({ name: "error404" });
      }
    },
    checkInquirydata() {
      let myquery = this.$route.query;
      let countKey = Object.keys(myquery).length;
      // process add `outcome` query to url
      let shiftVal = 0;
      if (myquery.outcome === "email") {
        shiftVal += 1;
        this.pageParam.outcome = myquery.outcome;
      }

      if (myquery.recommend_page_url && countKey == 1) {
        this.pageParam.recommend_page_url = myquery.recommend_page_url;
        this.recommendPropertiesUrl = myquery.recommend_page_url;
        this.fetchInquiry(this.pageParam, "url");
      } else if (
        myquery.new_mail_property_page_url &&
        countKey == 1 + shiftVal
      ) {
        this.recommendPropertiesUrl = myquery.new_mail_property_page_url;
        this.fetchNewMail(myquery.new_mail_property_page_url);
      } else if (
        myquery.recommend_mail_id > 0 &&
        myquery.customer_id > 0 &&
        countKey == 2 + shiftVal
      ) {
        this.mailParam.recommend_mail_id = parseInt(myquery.recommend_mail_id);
        this.mailParam.customer_id = parseInt(myquery.customer_id);
        this.fetchInquiry(this.mailParam, "mail");
      }
      // else if (
      //   myquery.history_id &&
      //   myquery.customer_id > 0 &&
      //   myquery.reference_page_url != "" &&
      //   countKey == 3
      // ) {
      //   this.followParam.history_id = parseInt(myquery.history_id);
      //   this.followParam.customer_id = parseInt(myquery.customer_id);
      //   this.followParam.reference_page_url = myquery.reference_page_url;
      //   this.fetchInquiry(this.followParam, "follow");
      // }
      else {
        this.$router.push({ name: "error404" });
      }
      localStorage.setItem("inquiryform", false);
      localStorage.setItem("inquirysubmitstatus", false);
      if (this.$route.query)
        localStorage.setItem("inquirybackurl", this.$route.fullPath);
    },
    checkSubmit(ev) {
      ev.preventDefault();
      this.checkField();
      if (this.isSubmit && !this.formConfirm()) {
        this.$router.push({ name: "inquiry-confirm" });
        return false;
      }
      if (this.formConfirm()) {
        this.submitForm();
      }
    },
    submitForm() {
      let body = null;
      body = {
        telephone_number: this.dataParams.phone,
        inquiry_note: this.dataParams.request,
        preferred_contact_time: this.dataParams.selectContactTime,
        inquiry_check_box_1: this.dataParams.inquiryCheck1,
        inquiry_check_box_2: this.dataParams.inquiryCheck2,
        inquiry_check_box_3: this.dataParams.inquiryCheck3,
        pre_submit: false,
      };
      if (this.typeSubmit == "url")
        body.recommend_page_url = this.pageParam.recommend_page_url;
      if (this.typeSubmit == "urlnew")
        body.new_mail_property_page_url = this.recommendPropertiesUrl;
      if (this.typeSubmit == "mail") {
        body.recommend_mail_id = this.mailParam.recommend_mail_id;
        body.customer_id = this.mailParam.customer_id;
      }
      // if (this.typeSubmit == "follow") {
      //   body.history_id = this.followParam.history_id;
      //   body.customer_id = this.followParam.customer_id;
      //   body.reference_page_url = this.followParam.reference_page_url;
      // }
      if (this.formConfirm())
        addInquiryHistories(body)
          .then((_) => {
            localStorage.setItem("inquiryform", true);
            localStorage.setItem("inquirysubmitstatus", true);
            this.$router.push({ name: "inquiry-finish" });
          })
          .catch((_) => {
            localStorage.setItem("inquiryform", true);
            localStorage.setItem("inquirysubmitstatus", false);
            this.$router.push({ name: "inquiry-finish" });
          });
    },
    backForm(ev) {
      ev.preventDefault();
      this.$router.go(-1);
    },
    backRecomend(ev) {
      ev.preventDefault();
      let urlRecomend = this.$route.path.split("/");
      let nameRoute = urlRecomend.includes("new")
        ? "new_mail_recommend"
        : "recommend";
      this.$router.push({
        name: nameRoute,
        params: { uuid: this.recommendPropertiesUrl },
      });
    },
    checkField() {
      this.startCheck = true;
      if (
        this.isConfirm == false ||
        !this.isCheckBox() ||
        this.dataParams.phone == ""
      ) {
        this.isSubmit = false;
      } else {
        this.isSubmit = true;
      }
    },
    checkPhone(evt) {
      var key;
      if (evt.type === "paste") {
        key = evt.clipboardData.getData("text/plain");
      } else {
        key = evt.keyCode || evt.which;
        key = String.fromCharCode(key);
      }
      var regex = /[0-9]|-/;
      if (!regex.test(key) || evt.target.value.length > 15) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault();
      }
    },
    checkIsPhone() {
      var regex = /^[0-9]|[0-9]-[0-9]$/;
      if (!regex.test(this.dataParams.phone)) {
        return true;
      }
      return false;
    },
    isCheckBox() {
      if (
        this.dataParams.inquiryCheck1 ||
        this.dataParams.inquiryCheck2 ||
        this.dataParams.inquiryCheck3 ||
        this.dataParams.request
      )
        return true;
      return false;
    },
    formConfirm() {
      return this.$route.name == "inquiry-confirm";
    },
    isEnterFeild() {
      if (
        this.dataParams.inquiryCheck1 == false &&
        this.dataParams.inquiryCheck2 == false &&
        this.dataParams.inquiryCheck3 == false &&
        this.dataParams.request == "" &&
        this.dataParams.selectContactTime == null &&
        this.dataParams.phone == "" &&
        this.isConfirm == false
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>
