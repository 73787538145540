<template>
  <div class="wrapper hold-transition single-page">
    <div class="content-wrapper">
      <section class="content py-3">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body minh-520 p-0">
              <slot></slot>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSingleLayout",
};
</script>
