<template>
  <h3 class="card-page-title mt-4 mb-3">
    <img
      src="@/assets/img/property-feat/feat-form-title.png"
      alt="title"
      class="img-fluid" />
  </h3>
  <div class="wrap-ct border-0">
    <div class="text-blue3 my-4 text-center font-weight-bold fs-20">
      {{ pageTitle }} 来場予約
    </div>
    <div v-if="statusSubmit" class="my-4 text-center font-weight-bold fs-20">
      来場予約フォームを送信しました。
    </div>
    <div v-if="!statusSubmit" class="text-center">
      <div class="my-4 text-center font-weight-bold fs-20">
        エラーが発生しました。もう一度トライしてください
      </div>
      <button class="btn submit-pink submit-border-pink m-3" @click="backForm">
        <span>戻 る</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VisitingReservationFinish",
  data() {
    return {
      statusSubmit: true,
      backUrl: "/",
      pageTitle: "",
    };
  },
  created() {
    let isConfirm = JSON.parse(localStorage.getItem("postvisit"));
    this.statusSubmit = JSON.parse(
      localStorage.getItem("visitresersubmitstatus")
    );
    this.backUrl = localStorage.getItem("visitreserbackurl");
    this.pageTitle = localStorage.getItem("visitresertitle");
    localStorage.removeItem("postvisit");
    if (!isConfirm) this.$router.push({ name: "error404" });
  },
  methods: {
    backForm(ev) {
      ev.preventDefault();
      location.href = this.backUrl;
    },
  },
};
</script>
