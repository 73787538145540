<template>
  <NewInHouseMail
    v-if="renderNewInHouse"
    :data="showData"
    :text-button="textButton"
    :schedule="schedule"
    :is-show-component-mail="false" />
  <NewOtherCompanyMail
    v-if="renderNewOtherCompany"
    :data="showData"
    :text-button="textButton"
    :schedule="schedule"
    :is-show-component-mail="false" />
</template>

<script>
import {
  getPreviousDay,
  returnTextButton,
} from "/src/components/recommend-properties/commons/utils/format.js";
import { getParamsUrl } from "/src/service/utility.service";
import { getUuid } from "/src/components/recommend-properties/commons/utils/format.js";
import { getRecommendScheduleCalendars } from "/src/service/recommend-schedule-calendar.js";
import { ORIGINAL_LOCATION } from "/src/constants.js";
import {
  getNewMailPropertyDetail,
  inCreasePageViewNewMail,
} from "/src/service/recommend.service.js";
import NewOtherCompanyMail from "/src/components/recommend-properties/NewOtherCompanyMail.vue";
import NewInHouseMail from "/src/components/recommend-properties/NewInHouseMail.vue";
import { showData } from "/src/helpers/info-properties.js";
import {
  getNewInHouseHeader,
  getHouseImage,
  getNewInHouseIcon,
  getNewInHouseTable,
  getNewInHouseMapImage,
  getTourAvailable,
} from "/src/components/recommend-properties/commons/utils/newInHouse.js";

export default {
  components: {
    NewInHouseMail,
    NewOtherCompanyMail,
  },
  data() {
    return {
      getUuid,
      getParamsUrl,
      showData,
      textButton: "",
      ORIGINAL_LOCATION,
      renderNewInHouse: false,
      renderNewOtherCompany: false,
      data: { property_block: {} },
      schedule: {
        release_date: "",
        expiry_date: "",
        expiry_date_before_one_day: "",
      },
    };
  },
  created() {
    const uuid = getUuid();
    inCreasePageViewNewMail(uuid);
    getNewMailPropertyDetail(uuid).then((data) => {
      try {
        this.data.property_block = { ...data.data, ...data.data.block };
        this.textButton = returnTextButton(
          data.data.block.block_classification,
          data.data.property.completion_date
        );
        if (
          data.data.property.location_of_original_data ==
          ORIGINAL_LOCATION.DYNAMIC
        ) {
          this.renderNewInHouse = true;
        } else if (
          data.data.property.location_of_original_data ==
          ORIGINAL_LOCATION.OTHER
        ) {
          this.renderNewOtherCompany = true;
        }
        getNewInHouseHeader(this.data, this.showData);
        getHouseImage(this.data, this.showData);
        getNewInHouseIcon(this.data, this.showData);
        getNewInHouseTable(this.data, this.showData);
        getNewInHouseMapImage(this.data, this.showData);
        getTourAvailable(this.data, this.showData);
      } catch {
        if (data.response.status != 200) {
          this.$router.push("/error404");
        }
      }
    });
    getRecommendScheduleCalendars().then((data) => {
      this.schedule.release_date = data.data.release_date;
      this.schedule.expiry_date = data.data.expiry_date;
      this.schedule.expiry_date_before_one_day = getPreviousDay(
        data.data.expiry_date
      );
    });
  },
};
</script>
