import { createRouter, createWebHistory } from "vue-router";
import RecommendProperties from "./views/recommend/RecommendProperties.vue";
import RecommendNewMail from "/src/views/recommend/RecommendNewMail.vue";
import RecommendNewMailProperties from "/src/views/recommend/RecommendNewMailProperties.vue";
import PageNotFoundCustomer from "/src/views/PageNotFoundCustomer.vue";
import {
  VisitingReservation,
  VisitingReservationForm,
  VisitingReservationFinish,
} from "/src/views/customer-app/visiting-reservation/index.js";
import {
  Inquiry,
  InquiryForm,
  InquiryFinish,
} from "/src/views/customer-app/inquiry/index.js";

let routes = [
  // TLI10001
  {
    path: "/recommend",
    meta: { title: "【東急リバブル】マンション紹介" },
    children: [
      {
        path: ":uuid",
        name: "recommend",
        component: RecommendProperties,
      },
      {
        path: "",
        name: "recommend_new_mail",
        component: RecommendNewMail,
      },
      {
        path: "new/:uuid",
        name: "new_mail_recommend",
        component: RecommendNewMailProperties,
      },
    ],
  },
  // TLI11002
  {
    path: "/visiting-reservation/",
    name: "visiting-reservation",
    component: VisitingReservation,
    meta: { title: "【東急リバブル】来場予約" },
    children: [
      {
        path: "",
        name: "visiting-main",
        component: VisitingReservationForm,
      },
      {
        path: ":uuid",
        name: "visiting-form",
        component: VisitingReservationForm,
      },
      {
        path: "new/:newuuid",
        name: "visiting-form-new",
        component: VisitingReservationForm,
      },
      {
        path: "confirm",
        name: "visiting-confirm",
        component: VisitingReservationForm,
      },
      {
        path: "finish",
        name: "visiting-finish",
        component: VisitingReservationFinish,
      },
    ],
  },
  // TLI11003
  {
    path: "/inquiry",
    name: "inquiry",
    component: Inquiry,
    meta: { title: "【東急リバブル】お問合せ" },
    children: [
      {
        path: "",
        name: "inquiry-form",
        component: InquiryForm,
      },
      {
        path: "new",
        name: "inquiry-form-new",
        component: InquiryForm,
      },
      {
        path: "confirm",
        name: "inquiry-confirm",
        component: InquiryForm,
      },
      {
        path: "finish",
        name: "inquiry-finish",
        component: InquiryFinish,
      },
    ],
  },
  {
    path: "/error404",
    name: "error404",
    component: PageNotFoundCustomer,
  },
  { path: "/:pathMatch(.*)*", redirect: { name: "error404" } },
];

export const routers_customer = createRouter({
  history: createWebHistory(),
  routes,
});
routers_customer.beforeEach(async (to, from, next) => {
  document.title = createTitle(to);
  next();
});
function createTitle(to) {
  // For constant title != 件レコメンドAIシステム, set title in meta tag
  // For title varies by data, set title in component
  return to.meta.title || " 東急リバブル";
}
