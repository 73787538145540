<template>
  <BaseSingleLayout>
    <h3 class="card-page-title mt-4 mb-3">
      <img
        src="@/assets/img/property-feat/feat-form-title.png"
        alt="title"
        class="img-fluid" />
    </h3>
    <div class="wrap-ct border-0">
      <div class="text-blue3 my-4 text-center font-weight-bold fs-20">
        {{ pageTitle }} お問い合わせフォーム
      </div>
      <div v-if="statusSubmit" class="my-4 text-center font-weight-bold fs-20">
        お問合せフォームを送信しました。
      </div>
      <div v-if="!statusSubmit" class="text-center">
        <div class="my-4 text-center font-weight-bold fs-20">
          エラーが発生しました。もう一度トライしてください
        </div>
        <button
          class="btn submit-pink submit-border-pink m-3"
          @click="backForm">
          <span>戻 る</span>
        </button>
      </div>
    </div>
  </BaseSingleLayout>
</template>

<script>
import BaseSingleLayout from "/src/components/layouts/BaseSingleLayout.vue";

export default {
  name: "InquiryFinish",
  components: {
    BaseSingleLayout,
  },
  data() {
    return {
      statusSubmit: true,
      backUrl: "/",
      pageTitle: "",
    };
  },
  created() {
    let isConfirm = JSON.parse(localStorage.getItem("inquiryform"));
    this.statusSubmit = JSON.parse(localStorage.getItem("inquirysubmitstatus"));
    this.backUrl = localStorage.getItem("inquirybackurl");
    this.pageTitle = localStorage.getItem("inquirytitle");
    localStorage.removeItem("inquiryform");
    localStorage.removeItem("inquirytitle");
    if (!isConfirm) this.$router.push({ name: "error404" });
  },
  methods: {
    backForm(ev) {
      ev.preventDefault();
      location.href = this.backUrl;
    },
  },
};
</script>
