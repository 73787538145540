import { createApp } from "vue";
import { routers_customer } from "./routers_customer";

import App from "./App.vue";
import store from "./store/index.js";

/* css */
import "/src/assets/fonts/fontawesome/css/all.min.css";
import "admin-lte/dist/css/adminlte.min.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "/src/assets/css/style.css";
/* js */
import jquery from "jquery";
import bootstrap from "bootstrap";
import adminltejs from "admin-lte";
import VueDatePicker from "@vuepic/vue-datepicker";

// import "./helpers/axios-interceptors.js";
import { globalFunction } from "./globalFunction";
let app = createApp(App);

app.use(routers_customer);
app.use(store);
app.config.globalProperties.func = globalFunction;
/* plugin */
app.use(jquery, adminltejs, bootstrap);
app.component("VueDatePicker", VueDatePicker);

app.mount("#app");
