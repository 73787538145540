import axios from "axios";
import { INQUIRY_HISTORIES_API } from "/src/service/const.service.js";

const getInquiryHistories = async (params) => {
  try {
    const response = await axios.get(INQUIRY_HISTORIES_API, {
      params: params,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getInquiryDetail = async (inquiry_id) => {
  try {
    const response = await axios.get(`${INQUIRY_HISTORIES_API}/${inquiry_id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const updateInquiryStatus = async (inquiry_id, follow_status) => {
  const body = { follow_status: follow_status };
  try {
    const response = await axios.patch(
      `${INQUIRY_HISTORIES_API}/${inquiry_id}`,
      body
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const addInquiryHistories = async (body) => {
  try {
    const response = await axios.post(INQUIRY_HISTORIES_API, body);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const checkInquiryHistories = async (body) => {
  try {
    const response = await axios.post(INQUIRY_HISTORIES_API, body);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export {
  getInquiryHistories,
  getInquiryDetail,
  updateInquiryStatus,
  addInquiryHistories,
  checkInquiryHistories,
};
